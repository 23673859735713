@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: black;
  color: white;
}

.chat-standard-width {
  width: 21.25rem; /* 340px; same as twitch */
}

#streams-outer-container {
  min-height: 5rem;
}

.btn {
  @apply focus:ring-2 outline-none border-2 rounded-sm;
}

.btn-sidebar {
  @apply btn px-2 py-1 mx-3 my-1;
}

.btn-sidebar-followed {
  @apply ml-4 my-2;
}

.btn-txt {
  @apply ml-3 cursor-pointer self-center whitespace-nowrap;
}

.sidebar-stream-name {
  @apply mr-2 text-sm overflow-ellipsis overflow-hidden flex-shrink;
  max-width: 6.5rem;
}

.sidebar-stream-game {
  @apply text-xs text-gray-400 overflow-ellipsis overflow-hidden whitespace-nowrap;
  max-width: theme('spacing.36');
}

.sidebar .btn-txt {
  display: none;
}

.sidebar.open .btn-txt {
  display: unset;
}

.flip-horizontal {
  transform: scaleX(-1);
}

.scrollbar-width-thin {
  /* https://caniuse.com/css-scrollbar */
  /* firefox only */
  scrollbar-width: thin;
}

.scrollbar-width-thin::-webkit-scrollbar {
  width: 0.5rem;
  background-color: theme('colors.gray.900');
}

.scrollbar-width-thin::-webkit-scrollbar-thumb {
  background-color: theme('colors.gray.600');
}

.explainer p {
  @apply mb-4;
}

.followed-stream-tooltip {
  @apply absolute bg-gray-900 drop-shadow-md filter rounded py-1 px-3 text-sm align-middle;
}

.max-w-40 {
  max-width: theme('spacing.40');
}

.min-w-32 {
  min-width: theme('spacing.32');
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.modal {
  max-height: 80vh;
}

iframe {
  outline: none !important;
}